// przelaczenie cennika menu
const menuPricingItems = document.querySelectorAll(".menu-pricing-item");
if (menuPricingItems) {
  menuPricingItems.forEach((item) => {
    item.addEventListener("click", () => {
      menuPricingItems.forEach((item) => {
        item.classList.remove("active-item");
      });

      item.classList.add("active-item");

      const cats = document.querySelectorAll(".menuPricing__category");
      cats.forEach((cat) => {
        cat.style.display = "none";
      });

      const categoryToDisplay = document.querySelector(`#${item.dataset.menu}`);
      categoryToDisplay.style.display = "block";
    });
  });
}
